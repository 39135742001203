import { createGlobalStyle, DefaultTheme } from 'styled-components';

import { darkTheme, reset, typography } from './values';
import { screens } from './values/screens';

export const GlobalStyle = createGlobalStyle<{ theme: any }>`
  /* Reset Css
  ======================================================================================== */
  ${reset}

  /* Root Variables
  ======================================================================================== */
  :root {
    --font-primary: ${({ theme }) => theme.typography.fontFamily};

    --fz-base: ${({ theme }) => theme.typography.fontSize.base};
    --fz-1: calc(var(--fz-base) * 2.5);
    --fz-2: calc(var(--fz-base) * 2);
    --fz-3: calc(var(--fz-base) * 1.75);
    --fz-4: calc(var(--fz-base) * 1.5);
    --fz-5: calc(var(--fz-base) * 1.25);
    --fz-6: var(--fz-base);

    --fw-medium: ${({ theme }) => theme.typography.fontWeight.medium};
    --fw-semi-bold: ${({ theme }) => theme.typography.fontWeight.semiBold};

    --animation-duration: 0.3s;
    --disabled-opacity: 0.2;
    --state-active-color: ${({ theme }) => theme.colors.secondary[60]};
    --state-default-color: ${({ theme }) => theme.colors.white};
    --transition-duration: 0.3s;
  }

  
  /* Body
  ======================================================================================== */
  body {
    background-color: ${({ theme }) => theme.colors.paper};
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.typography.fontFamily};
    font-size: 100%;
    overflow-x:clip;
  }
  
  `;

export const theme: DefaultTheme = {
  colors: darkTheme,
  typography,
  screens,
};
