export const breakpoints = {
  xs: '0px',
  sm: '576px',
  md: '768px',
  lg: '1024px',
  xl: '1200px',
  xxl: '1400px',
};

const mediaQueries = {
  'md-lg': `@media screen and (min-width: ${breakpoints.md}) and (max-width: ${breakpoints.lg})`,
  md: `@media screen and (min-width: ${breakpoints.md})`,
  lg: `@media screen and (min-width: ${breakpoints.lg})`,
  xl: `@media screen and (min-width: ${breakpoints.xl})`,
  'md-height': `@media only screen and (max-height: ${breakpoints.md})`,
  desktop: ` @media screen and (min-width: ${breakpoints.lg})`,
};

export const screens = {
  breakpoints,
  mediaQueries,
};
