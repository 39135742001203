import { Color, StateColor } from '../../models/theme.model';

const primary: Color = {
  100: '#0B002A',
  90: '#170053',
  80: '#22007D',
  70: '#2E00A6',
  60: '#3900D0',
  50: '#4D1AD5',
  40: '#744DDE',
  30: '#9C80E8',
  20: '#C4B3F1',
  10: '#EBE6FA',
};

const secondary: Color = {
  100: '#061E29',
  90: '#0B3D52',
  80: '#115B7A',
  70: '#167AA3',
  60: '#1C98CC',
  50: '#33A2D1',
  40: '#49ADD6',
  30: '#8ECCE6',
  20: '#BBE0F0',
  10: '#E8F5FA',
};

const neutral: Color = {
  100: '#161616',
  90: '#202020',
  80: '#393939',
  70: '#525252',
  60: '#6F6F6F',
  50: '#8D8D8D',
  40: '#A8A8A8',
  30: '#C6C6C6',
  20: '#E0E0E0',
  10: '#F4F4F4',
};

const stateConfirmed: StateColor = {
  100: '#083F21',
  80: '#13924E',
  60: '#1BD16F',
  40: '#76E3A9',
  20: '#D1F6E2',
};

const stateError: StateColor = {
  100: '#450F0B',
  80: '#A0221A',
  60: '#E53125',
  40: '#EF837C',
  20: '#FAD6D3',
};

const stateAlert: StateColor = {
  100: '#4C3C13',
  80: '#997925',
  60: '#FFC93E',
  40: '#FFDF8B',
  20: '#FFF4D8',
};

const palette = {
  white: '#FFFFFF',
  black: '#000000',
  primary,
  secondary,
  neutral,
  stateConfirmed,
  stateError,
  stateAlert,
};

export const gradients = {
  1: { colorA: '#3901c899', colorB: '#19adcc99' }, // ? color asigned in Draxo Coming back soon
  2: { colorA: '#3901c899', colorB: '#1976cc99' }, // ? color asigned in Draxo Coming back soon
  3: { colorA: '#2930cf4c', colorB: '#000aff31' }, // ? color asigned in Draxo Coming back soon
  blue: { primary80: primary[80], primary60: primary[60] },
  aqua: { primary70: primary[70], secondary50: secondary[50] },
  black: { neutral100: neutral[100], neutral70: neutral[70] },
  gray: { neutral50: neutral[50], neutral30: neutral[30] },
};

export const darkTheme = {
  paper: '#040404', // ? color asigned in Draxo Coming back soon
  text: palette.white,
  ...palette,
  gradients,
};

export const lightTheme = {
  paper: palette.white, // ? color asigned in Draxo Coming back soon
  text: '#090A0D',
  ...palette,
};
