import {
  CommonWeight,
  FontSize,
  FontWeiht,
  LineHeight,
} from '../../models/theme.model';

const fontSize: FontSize = {
  xs: '.75rem', // TODO: DELETE, only used on Draxo and will be back soon!
  sm: '.875rem', // TODO: DELETE, only used on Draxo and will be back soon!
  base: '1rem', // TODO: DELETE, only used on Draxo and will be back soon!
  lg: '1.125rem', // TODO: DELETE, only used on Draxo and will be back soon!
  xl: '1.25rem', // TODO: DELETE, only used on Draxo and will be back soon!
  xxl: '1.5rem', // TODO: DELETE, only used on Draxo and will be back soon!
  h1: '4.625rem', // 74px
  h2: '4rem', // 64px
  h3: '3.375rem', // 54px
  h4: '2.75rem', // 44px
  h5: '2.25rem', // 36px
  title1: '1.875rem', // 30px
  title2: '1.5rem', // 24px
  subTitle1: '1.25rem', // 20px
  subTitle2: '1.125rem', // 18px
  bodyText1: '1rem', // 16px
  bodyText2: '.875rem', // 14px
  paragraph1: '.75rem', // 12px
  paragraph2: '.6875rem', // 11px
};

const fontWeight: FontWeiht = {
  regular: CommonWeight.normal, // * Poppins
  medium: CommonWeight.medium, // * Poppins
  semiBold: CommonWeight.semiBold, // * Poppins
  bold: CommonWeight.bold, // * Poppins
};

const spacing = {
  xs: '.25rem', // TODO: DELETE, only used on Draxo and will be back soon!
  sm: '.5rem', // TODO: DELETE, only used on Draxo and will be back soon!
  base: '1rem', // TODO: DELETE, only used on Draxo and will be back soon!
  lg: '1.5rem', // TODO: DELETE, only used on Draxo and will be back soon!
  xl: '2rem', // TODO: DELETE, only used on Draxo and will be back soon!
  xxl: '3rem', // TODO: DELETE, only used on Draxo and will be back soon!
};

const lineHeight: LineHeight = {
  80: '5rem', // 80px
  70: '4.375rem', // 70px
  60: '3.75rem', // 60px
  50: '3.125rem', // 50px
  40: '2.5rem', // 40px
  36: '2.25rem', // 36px
  30: '1.875rem', // 30px
  26: '1.625rem', // 26px
  22: '1.375rem', // 22px
  20: '1.25rem', // 20px
  18: '1.125rem', // 18px
  16: '1rem', // 16px
  15: '.9375rem', // 15px
};

export const typography = {
  fontFamily: `'Poppins', sans-serif`,
  fontSize,
  fontWeight,
  lineHeight,
  spacing,
};
