exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-coming-back-index-tsx": () => import("./../../../src/pages/coming-back/index.tsx" /* webpackChunkName: "component---src-pages-coming-back-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-terms-conditions-index-tsx": () => import("./../../../src/pages/terms-conditions/index.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-index-tsx" */),
  "component---src-pages-themes-brand-index-tsx": () => import("./../../../src/pages/themes/brand/index.tsx" /* webpackChunkName: "component---src-pages-themes-brand-index-tsx" */),
  "component---src-pages-themes-buttons-index-tsx": () => import("./../../../src/pages/themes/buttons/index.tsx" /* webpackChunkName: "component---src-pages-themes-buttons-index-tsx" */),
  "component---src-pages-themes-colors-index-tsx": () => import("./../../../src/pages/themes/colors/index.tsx" /* webpackChunkName: "component---src-pages-themes-colors-index-tsx" */),
  "component---src-pages-themes-desktop-menu-index-tsx": () => import("./../../../src/pages/themes/desktop-menu/index.tsx" /* webpackChunkName: "component---src-pages-themes-desktop-menu-index-tsx" */),
  "component---src-pages-themes-mobile-menu-index-tsx": () => import("./../../../src/pages/themes/mobile-menu/index.tsx" /* webpackChunkName: "component---src-pages-themes-mobile-menu-index-tsx" */),
  "component---src-pages-themes-typography-index-tsx": () => import("./../../../src/pages/themes/typography/index.tsx" /* webpackChunkName: "component---src-pages-themes-typography-index-tsx" */)
}

