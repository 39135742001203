import { css } from 'styled-components';

export const reset = css`
  html {
    box-sizing: border-box;
    font-size: 100%;
    scroll-behavior: smooth;
  }

  *,
  *:before,
  *:after {
    border: 0;
    box-sizing: inherit;
    margin: 0;
    padding: 0;
  }

  /* Reset links to function as boxes... */
  a {
    color: inherit;
    display: block;
    font-size: inherit;
    text-decoration: none;
  }
  /* ... except in the paragraphs */
  p a {
    display: inline;
  }

  /* Removed bullets from the <li> */
  li {
    list-style-type: none;
  }

  button {
    font-family: inherit;
  }
`;
